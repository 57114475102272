<script lang="ts" setup>
import { onMounted, ref, watch } from "vue";
// import { CurrentInvitationStore } from "../../stores/CurrentInvtationStore";
import { appContainer } from "../../container";
import { LoadingService } from "../../services/loadingService";
import { ManageQuestionService } from "../../services/manageQuestionService";
import { PagingInterface } from "../../interface/PaginationInterface";
import {
  QuestionInputTypes,
  QuestionResponse,
  QuestionResponseSubCategories,
  QuestionTypes,
  ResponseInterFace,
} from "../../interface/QuestionInterface";
import RaterQuestionComponent from "./RaterQuestionComponent.vue";
import { ToastService } from "../../services/toastService";
import FinishFeedbackModal from "./FinishFeedbackModal.vue";
import router from "../../routes";
import { AllRaterType } from "../../interface/UserInterface";
import { useUserStore } from "../../stores/UserStore";

// const currentInvitationStore = CurrentInvitationStore();
const userStore = useUserStore();
const loadingService = appContainer.resolve(LoadingService);
const questionService = appContainer.resolve(ManageQuestionService);
const toastService = appContainer.resolve(ToastService);

const paginatedQuestionResponse = ref<PagingInterface<QuestionResponse>>();
const paginatedCompanyResponses = ref<PagingInterface<QuestionResponse>>();
const companyResponses = ref<QuestionResponse[]>([]);
const questionResponses = ref<QuestionResponse[]>([]);
const responseArray = ref<ResponseInterFace[]>([]);
const pageConfig = ref({ page: 1, offset: 4 });
const subCategoryValues = Object.values(QuestionResponseSubCategories);

const category = ref("");
const subCategory = ref("");
const feedbackModal = ref(false);
const totalPages = ref();
const currentInputType = ref();
const isLoading = ref(true);

onMounted(async () => {
  const loader = await loadingService.show();
  await userStore.getRater();
  try {
    if (userStore.invitation?.company_id) {
      await getCompanyQuestions();
    }
    if (companyResponses.value.length) {
      totalPages.value = subCategoryValues.length + 1;
    } else {
      subCategoryValues.pop();
      totalPages.value = subCategoryValues.length + 1;
    }
    await getCurrentPage();
    await getQuestions();
    getCurrentCategory();
  } catch (error) {
    console.error("Error during mounted hook:", error);
  } finally {
    isLoading.value = false;
    loader.hide();
  }
});

async function getCompanyQuestions() {
  const loader = await loadingService.show();

  return await questionService
    .getFeedbackResponse({
      invitationId: userStore.invitation!.id,
      questionType: QuestionTypes.Company,
      all: true,
    })
    .then((result) => {
      paginatedCompanyResponses.value = result;
      companyResponses.value = result.items;
    })
    .finally(() => loader.hide());
}

async function getCurrentPage() {
  return questionService
    .getCurrentPage(userStore.invitation!.id)
    .then(async (result) => {
      subCategory.value = result.question.question_subcategory as string;
      pageConfig.value.page =
        subCategoryValues.indexOf(
          subCategory.value as QuestionResponseSubCategories,
        ) + 1;
      currentInputType.value = result.question.input_type;
      if (currentInputType.value === QuestionInputTypes.Paragraph) {
        pageConfig.value.page = totalPages.value;
      }
    });
}

function getCurrentCategory() {
  if (questionResponses.value.length > 0) {
    category.value =
      questionResponses.value[0].question.question_category || "";
    subCategory.value =
      questionResponses.value[0].question.question_subcategory || "";
  }
}
async function getQuestions() {
  const loader = await loadingService.show();
  return await questionService
    .getFeedbackResponse({
      invitationId: userStore.invitation!.id,
      subcategory: subCategory.value as QuestionResponseSubCategories,
      inputType: currentInputType.value,
    })
    .then((result) => {
      paginatedQuestionResponse.value = result;
      if (
        currentInputType.value === QuestionInputTypes.Paragraph &&
        result.items
      ) {
        result.items.sort((a, b) => b.question.id - a.question.id);
      }

      questionResponses.value = result.items;
    })
    .finally(() => loader.hide());
}

function handleCurrentResponse(response: ResponseInterFace) {
  if (!responseArray.value.find((item) => item.id === response.id)) {
    responseArray.value.push(response);
  }
}

async function submitResponses() {
  if (
    questionResponses.value[0].question.input_type ===
      QuestionInputTypes.Rating &&
    responseArray.value.filter((response) => response.rating_response === null)
      .length >= 1
  ) {
    toastService.error("Please Provide Rating for All Questions");
  } else {
    const loader = await loadingService.show();
    try {
      (document.querySelector(".app-page") as HTMLElement).scrollTop = 0;
      await questionService.submitResponse(
        responseArray.value,
        userStore.invitation!.id,
      );
      const currentIndex = subCategoryValues.indexOf(
        subCategory.value as QuestionResponseSubCategories,
      );
      const nextIndex = (currentIndex + 1) % subCategoryValues.length;
      subCategory.value = subCategoryValues[nextIndex];
      if (pageConfig.value.page != totalPages.value) {
        pageConfig.value.page += 1;
      }
      responseArray.value = [];
      if (pageConfig.value.page === totalPages.value) {
        currentInputType.value = QuestionInputTypes.Paragraph;
        subCategory.value = "";
      }

      if (pageConfig.value.page <= totalPages.value) {
        await getQuestions();
      }
    } catch (error) {
      toastService.error("Error submitting responses");
    } finally {
      loader.hide();
    }
  }
}

function handleBackBtn() {
  const currentIndex =
    subCategory.value === ""
      ? subCategoryValues.length
      : subCategoryValues.indexOf(
          subCategory.value as QuestionResponseSubCategories,
        );
  const prevIndex = (currentIndex - 1) % subCategoryValues.length;
  subCategory.value = subCategoryValues[prevIndex];
  pageConfig.value.page -= 1;
  currentInputType.value =
    pageConfig.value.page === totalPages.value
      ? QuestionInputTypes.Paragraph
      : QuestionInputTypes.Rating;
  responseArray.value = [];
  getQuestions();
}

watch(questionResponses, () => {
  getCurrentCategory();
});

async function handleFinishFeedback() {
  await submitResponses();
  feedbackModal.value = true;
}
</script>

<template>
  <div v-if="!isLoading" class="fit-height d-flex flex-column">
    <div class="question-header" v-if="category">
      <strong class="fs-5">Questions</strong>
      <p class="mt-2">
        Please select the number that most accurately describes
        <strong>
          {{
            userStore.invitation?.rater_type === AllRaterType.Self
              ? "You"
              : `${userStore.invitation?.invited_for_user?.first_name} ${userStore.invitation?.invited_for_user?.last_name}`
          }}
        </strong>
      </p>
    </div>
    <div v-else class="question-header mb-3">
      <strong class="fs-5">Written Comments</strong>
    </div>
    <div
      class="d-flex align-items-start mb-sm-5 flex-column"
      :class="{ 'mb-sm-5 pb-5': category }"
    >
      <button
        class="btn btn-primary text-white text-white"
        type="button"
        @click="handleBackBtn"
        :disabled="subCategory === QuestionResponseSubCategories.SetDirection"
      >
        Back
      </button>
      <div
        class="mx-auto"
        v-if="category && !(category === QuestionResponseSubCategories.Custom)"
      >
        <h3 class="ff-montserrat mb-0 text-primary text-center">
          {{ category }}
        </h3>
        <h6 class="ff-montserrat mb-0 grey-color text-center">
          {{ subCategory }}
        </h6>
      </div>
      <div
        v-if="category && category === QuestionResponseSubCategories.Custom"
        class="mx-auto"
      >
        <h3 class="ff-montserrat mb-0 text-primary text-center">
          Organization
        </h3>
        <h6 class="ff-montserrat mb-0 grey-color text-center">
          Values/Priorities
        </h6>
      </div>
      <!-- <button
        v-if="pageConfig.page === totalPages"
        class="btn btn-primary text-white"
        type="button"
        @click="handleFinishFeedback"
      >
        Finish
      </button>
      <button
        v-else
        class="btn btn-primary text-white"
        type="button"
        @click="submitResponses"
      >
        Continue
      </button> -->
    </div>

    <form class="d-flex flex-column" @submit.prevent="submitResponses">
      <RaterQuestionComponent
        v-for="(questionResponse, index) in questionResponses"
        :key="index"
        :question="questionResponse.question"
        :index="
          paginatedQuestionResponse
            ? (paginatedQuestionResponse?.page - 1) *
                paginatedQuestionResponse.offset +
              (index + 1)
            : 1
        "
        :currentResponse="questionResponse"
        @updateResponse="handleCurrentResponse"
      />
    </form>

    <div class="d-flex align-items-center my-md-5 my-4">
      <!-- <button
        class="btn btn-primary text-white text-white"
        type="button"
        @click="handleBackBtn"
        :disabled="subCategory === QuestionResponseSubCategories.SetDirection"
      >
        Back
      </button> -->
      <div
        class="d-flex align-items-center justify-content-center w-100 page-footer py-3"
      >
        <span>Page {{ pageConfig.page }} of {{ totalPages }}</span>
      </div>
      <button
        v-if="pageConfig.page === totalPages"
        class="btn btn-primary text-white text-nowrap"
        type="button"
        @click="handleFinishFeedback"
      >
        Save and Finish
      </button>
      <button
        v-else
        class="btn btn-primary text-white"
        type="button"
        @click="submitResponses"
      >
        Continue
      </button>
    </div>
  </div>
  <FinishFeedbackModal
    v-if="feedbackModal"
    @close="feedbackModal = false"
    @submitted="router.push('/thank-you')"
  />
</template>

<style lang="scss" scoped>
.fit-height {
  min-height: calc(100vh - 110px);
  overflow: auto;
}
.grey-color {
  color: #999;
}

.rating-heading {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 10px;
  span {
    width: 110px;
    font-size: 14px;
    font-weight: 600;
    text-wrap: nowrap;
    text-align: center;
  }
}
.start-6 {
  grid-column: 6;
}
.page-footer {
  .btn {
    min-width: 100px;
  }
}
@media (max-width: 992px) {
  form {
    height: 100%;
  }
  h3 {
    font-size: 20px;
  }
  h6 {
    font-size: 14px;
  }
  button {
    padding: 6px;
    font-size: 14px;
  }
}
</style>
