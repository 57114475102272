<script setup lang="ts">
import { onMounted, ref } from "vue";
import { PdfResources } from "../../interface/ResourcesInterface";
import { StorageService } from "../../services/storage.service";
import { StorageKeys } from "../../services/storage.service";
import { appContainer } from "../../container";
import DeleteResourceModal from "./DeleteResourceModal.vue";
import { ActiveRolesStore } from "../../stores/ActiveRolesStore";
import { Roles } from "../../interface/UserInterface";

const props = defineProps<{
  resource: PdfResources;
}>();

const storageService = appContainer.resolve(StorageService);

const token = ref<string>("");
const resourceId = ref();
const showDeleteModal = ref(false);
const activeRoleStore = ActiveRolesStore();
const emit = defineEmits(["ResourceDeleted"]);

const formatDate = (dateString: Date | string) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  return date.toLocaleDateString("en-US", options);
};

function emitDelete() {
  showDeleteModal.value = false;
  emit("ResourceDeleted");
}

function deleteResource() {
  resourceId.value = props.resource.id;
  showDeleteModal.value = true;
}

function visibleTo(roles: string[]) {
  return activeRoleStore.activeRoles?.some((item) => roles.includes(item));
}

onMounted(async () => {
  token.value = (
    (await storageService.get(StorageKeys.TOKEN)) as any
  ).access_token;
});
</script>
<template>
  <div
    class="listing-company d-flex align-items-center w-100 py-3 border-bottom"
  >
    <div class="img-resource rounded overflow-hidden">
      <i class="bi bi-filetype-pdf"></i>
    </div>
    <div class="company-info px-2 mx-2">
      <h6 class="ff-montserrat fs-medium text-capitalize mb-1">
        {{ props.resource.name }}
      </h6>
      <div class="d-flex gap-2 size-sm">
        <p class="mb-0">
          <span>Uploaded on: </span>
          <strong class="fw-medium"
            >{{ formatDate(props.resource.created_at) }}
          </strong>
        </p>
      </div>
    </div>
    <div class="group-btns my-auto ms-auto flex-column flex-sm-row gap-2">
      <button
        class="border-0 bg-transparent"
        @click="deleteResource"
        v-if="visibleTo([Roles.SuperAdmin])"
      >
        <i class="bi bi-trash3"></i>
      </button>
      <button class="border-0 bg-transparent">
        <a
          target="_blank"
          :href="`${props.resource.file}?token=${token}`"
          class="btn border-0 bg-transparent p-0"
        >
          <i class="bi bi-download"></i>
        </a>
      </button>
    </div>
  </div>
  <DeleteResourceModal
    v-if="showDeleteModal"
    :resourceId="resourceId"
    @delete="emitDelete"
    @close="showDeleteModal = false"
  ></DeleteResourceModal>
</template>

<style lang="scss" scoped>
.size-sm {
  font-size: 12px;
}
.group-btns {
  display: flex;
  align-items: center;

  a {
    font-size: 18px;
    color: #000;
  }

  button {
    font-size: 18px;
    color: #000;
    &:nth-child(1):hover,
    &:nth-child(2):hover,
    &:nth-child(3):hover {
      color: #4a84dd;
    }

    &:nth-child(4):hover {
      color: red;
    }
  }
}
</style>
