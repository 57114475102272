<script setup lang="ts">
import { onBeforeMount, ref, watch } from "vue";
import { PagingInterface } from "../../../interface/PaginationInterface";
import { appContainer } from "../../../container";
import { UserService } from "../../../services/userService";
import { LoadingService } from "../../../services/loadingService";
import { useRoute } from "vue-router";
import PaginationComponent from "../../../components/PaginationComponent.vue";
import AddUserModal from "../../manage-users/components/AddUserModal.vue";
import UserListingCard from "../../manage-users/components/UserListingCard.vue";
import { ActiveYearStore } from "../../../stores/ActiveYearStore";
import PurchaseParticipantsModal from "../components/PurchaseParticipantsModal.vue";
import {
  InvitedUserInterface,
  Role,
  Roles,
} from "../../../interface/UserInterface";
// import { CompanyService } from "../../../services/manageCompanyService";
import { CompanyInterface } from "../../../interface/CompanyInterface";
import { CompanyService } from "../../../services/manageCompanyService";
// import { useUserStore } from "../../../stores/UserStore";

const paginatedData = ref<PagingInterface<InvitedUserInterface>>();
const invitedEmployees = ref<InvitedUserInterface[]>([]);

const companyService = appContainer.resolve(CompanyService);
const userService = appContainer.resolve(UserService);
const loadingService = appContainer.resolve(LoadingService);

const route = useRoute();
const searchField = ref("");
const showInviteEmployeeModal = ref(false);
const showPurchaseParticipantsModal = ref(false);
const isLoading = ref(true);
const activeYearStore = ActiveYearStore();
const invitationType = ref("");
const totalParticipants = ref<number>(0);
const currentCompany = ref<CompanyInterface>();
const allowedFeedbacks = ref<number>();

onBeforeMount(async () => {
  await getCurrentCompany();
  await getAllParticipants();
  await fetchInvitedEmployees().then(() => (isLoading.value = false));
  allowedFeedbacks.value = currentCompany?.value?.allowed_feedbacks ?? 0;
});

async function getAllParticipants() {
  const loader = await loadingService.show();
  return userService
    .getInvitedUser({
      companyId: Number(route.params.id),
      all: true,
      invitationType: Roles.Participant,
      invitation_sent: true,
    })
    .then((result) => {
      totalParticipants.value = result.items.length;
    })
    .finally(() => loader.hide());
}

async function getCurrentCompany() {
  return companyService.fetchCompanies().then((result) => {
    currentCompany.value = result.items.find(
      (company) => company.id === Number(route.params.id),
    );
  });
}

async function fetchInvitedEmployees(
  pageConfig: { page?: number; offset?: number } = {
    page: 1,
    offset: 8,
  },
) {
  const loader = await loadingService.show();
  return userService
    .getInvitedUser(
      {
        companyId: Number(route.params.id),
        name: searchField.value,
        year: activeYearStore.activeYear,
        invitationType: invitationType.value,
      },
      pageConfig,
    )
    .then((result) => {
      paginatedData.value = result;
      invitedEmployees.value = result.items;
    })
    .finally(() => loader.hide());
}

watch(
  () => activeYearStore.activeYear,
  () => {
    fetchInvitedEmployees();
  },
);

async function sendInvitation(_id: number, invitation_type: string) {
  fetchInvitedEmployees();

  if (invitation_type === Roles.Participant) {
    totalParticipants.value += 1;
  }
}

function updatePage(pageNumber: number) {
  if (pageNumber === paginatedData.value?.page) {
    return;
  }
  fetchInvitedEmployees({ page: pageNumber, offset: 8 });
}
async function editInvitation() {
  fetchInvitedEmployees();
}

function searchInvitedEmployees() {
  if (searchField.value === "") {
    return;
  }
  fetchInvitedEmployees();
}

watch(searchField, () => {
  if (searchField.value === "") {
    fetchInvitedEmployees();
  }
});

function employeeAdded() {
  showInviteEmployeeModal.value = false;
  fetchInvitedEmployees();
}

function userDeleted() {
  fetchInvitedEmployees();
}

watch(invitationType, () => {
  fetchInvitedEmployees();
});

function closePurchaseParticipantsModal() {
  showPurchaseParticipantsModal.value = false;
}

function handlePurchaseParticipants(participantCount: number) {
  allowedFeedbacks.value = Number(allowedFeedbacks.value) + participantCount;
  closePurchaseParticipantsModal();
}
</script>
<template>
  <template v-if="isLoading"></template>
  <template v-else>
    <div
      class="d-flex align-items-center justify-content-between flex-wrap gap-4"
    >
      <form
        class="d-flex justify-content-between search-wrapper align-items-center"
        @submit.prevent="searchInvitedEmployees"
      >
        <input
          type="search"
          class="form-control form-control-sm"
          placeholder="Search"
          v-model="searchField"
        /><button
          type="submit"
          class="text-white btn bg-primary flex-center btn-sm"
        >
          Search
        </button>
      </form>
      <div class="participants-info d-flex gap-3 ms-auto align-items-center">
        <p class="m-0">
          Participants: {{ totalParticipants }} /
          {{ allowedFeedbacks }}
        </p>
      </div>
      <button
        @click="showPurchaseParticipantsModal = true"
        class="btn btn-secondary btn-sm text-white"
      >
        Buy Participants
      </button>
      <button
        class="btn btn-primary btn-sm text-white"
        @click="showInviteEmployeeModal = true"
      >
        Add Person
      </button>
    </div>
    <ul class="nav nav-pills mt-4">
      <li
        class="nav-item p-2"
        :class="{ active: invitationType === '' }"
        @click="invitationType = ''"
      >
        <div class="d-flex align-items-center">
          <i class="bi bi-grid-fill"></i>
          All
        </div>
      </li>
      <li
        class="nav-item p-2"
        :class="{ active: invitationType === Role.Admin }"
        @click="invitationType = Role.Admin"
      >
        <div class="d-flex align-items-center">
          <i class="bi bi-gear-fill"></i>
          Admins
        </div>
      </li>
      <li
        class="nav-item p-2"
        :class="{ active: invitationType === Role.Participants }"
        @click="invitationType = Role.Participants"
      >
        <div class="d-flex align-items-center">
          <i class="bi bi-person-fill"></i>
          Participants
        </div>
      </li>
      <li
        class="nav-item p-2"
        :class="{ active: invitationType === Role.Raters }"
        @click="invitationType = Role.Raters"
      >
        <div class="d-flex align-items-center">
          <i class="bi bi-star-fill"></i>
          Raters
        </div>
      </li>
    </ul>
    <div class="employees-list pt-4" v-if="invitedEmployees.length">
      <UserListingCard
        v-for="(employee, index) in invitedEmployees"
        :key="index"
        :user="employee"
        @invite="sendInvitation"
        @edit="editInvitation"
        @delete="userDeleted"
        :companyId="Number(route.params.id)"
        :showDetail="false"
      />
    </div>
    <div
      class="no-result d-flex align-items-center justify-content-center text-opacity-50 text-danger vh-50 mt-5"
      v-else
    >
      No Records Found
    </div>
    <div class="pagination-wrapper" v-if="paginatedData?.totalItems">
      <PaginationComponent
        v-if="paginatedData.totalItems > paginatedData.offset"
        :totalRows="paginatedData!.totalItems"
        :currentPage="paginatedData?.page"
        :perPage="paginatedData?.offset"
        @pageChange="updatePage"
      />
    </div>

    <AddUserModal
      v-if="showInviteEmployeeModal"
      :companyId="Number(route.params.id)"
      @close="showInviteEmployeeModal = false"
      @add="employeeAdded"
    />

    <PurchaseParticipantsModal
      v-if="showPurchaseParticipantsModal"
      :companyId="Number(route.params.id)"
      @buy="handlePurchaseParticipants"
      @close="closePurchaseParticipantsModal"
    />
  </template>
</template>

<style lang="scss" scoped>
@import "../../../theme/variables";
.search-wrapper {
  max-width: 350px;
  gap: 8px;
}
.employees-list {
  min-height: calc(100% - 418px);
}

.nav-pills {
  gap: 10px;
}

.nav-item {
  border-radius: 0;
  border-bottom: 3px solid transparent;
  cursor: pointer;
  font-size: 14px;
  &.active {
    color: var(--bs-primary);
    border-color: var(--bs-primary);
  }
}

i {
  color: $blue;
  font-size: 18px;
  margin-right: 8px;
}
.bi-person-fill::before {
  font-size: 22px;
}
</style>
