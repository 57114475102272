<script setup lang="ts">
import { onMounted, ref } from "vue";
import { appContainer } from "../../container";
import { InvitedUserInterface, Role } from "../../interface/UserInterface";
import { LoadingService } from "../../services/loadingService";
import { UserService } from "../../services/userService";
import { useUserStore } from "../../stores/UserStore";
import UserAssessmentCard from "./UserAssessmentCard.vue";

const userService = appContainer.resolve(UserService);
const loadingService = appContainer.resolve(LoadingService);
const userStore = useUserStore();
const allAssessments = ref<InvitedUserInterface[]>([]);

onMounted(async () => {
  await getAllAssessments();
});

async function getAllAssessments() {
  const loader = await loadingService.show();
  await userService
    .getInvitedUser({
      invited_user: userStore.user?.id,
      invitationType: Role.Raters,
      all: true,
      allUsers: true,
    })
    .then((result) => {
      allAssessments.value = result.items;
    })
    .finally(() => loader.hide());
}
</script>
<template>
  <h2 class="fw-medium fs-4 p-0 mb-1">Feedback</h2>
  <p class="size-sm mb-0">Select a user to provide feedback</p>

  <div class="employees-list pt-4">
    <UserAssessmentCard
      v-for="assessment in allAssessments"
      :key="assessment.id"
      :assessment="assessment"
    />
  </div>
</template>
<style lang="scss" scoped>
.size-sm {
  font-size: 14px;
}
</style>
