<script lang="ts" setup>
import { onBeforeMount, onMounted, ref } from "vue";
import {
  InvitedUserInterface,
  InviteUserInterface,
  AllRaterTypeNames,
  Role,
  AllRaterType,
} from "../../interface/UserInterface";
import DeleteUserModal from "../manage-users/components/DeleteUserModal.vue";
import EditUserModal from "./modal/EditUserModal.vue";
import router from "../../routes";
import UserInvitationModal from "../manage-users/components/UserInvitationModal.vue";
import { appContainer } from "../../container";
import { ToastService } from "../../services/toastService";
import ProfileIconComponent from "../../components/ProfileIconComponent.vue";
import { CurrentInvitationStore } from "../../stores/CurrentInvtationStore";
import { getDaysLeft } from "../../utils/getDaysleft";
import { useUserStore } from "../../stores/UserStore";

const props = defineProps({
  user: {
    type: Object as () => InvitedUserInterface,
    required: true,
  },
  showDetail: {
    type: Boolean,
    default: true,
  },
  currentRole: {
    type: String,
  },
});

const emit = defineEmits(["invite", "delete", "edit", "click"]);

const showDeleteModal = ref(false);
const toBeDeleted = ref();
const currentModalUser = ref();
const showEditModal = ref(false);
const showInviteUserModal = ref(false);
const toastService = appContainer.resolve(ToastService);
const currentInvitationStore = CurrentInvitationStore();
const daysLeft = ref(0);
const participantsDaysLeft = ref(0);
const participant = ref<InvitedUserInterface | null>(null);
const rendericon = ref<boolean>();
const userStore = useUserStore();

onBeforeMount(async () => {
  await userStore.getInvitation(
    {
      invited_user: userStore.user?.id,
      invitationType: Role.Participants,
      all: true,
      allUsers: true,
    },
    "participant",
  );
  participant.value = userStore.invitation;

  if (participant.value) {
    participantsDaysLeft.value = getDaysLeft(participant.value);
  }
  rendericon.value =
    participant.value?.meta.feedback_done === null &&
    participantsDaysLeft.value >= 0 &&
    props.currentRole === Role.Participants;
});

function emitInvite(id: number) {
  closeInviteModal();
  emit("invite", id);
}

onMounted(() => {
  if (props.user.invitation_type === Role.Participants) {
    daysLeft.value = getDaysLeft(props.user);
  }
});

function openDeleteModal(id: number) {
  toBeDeleted.value = id;
  showDeleteModal.value = true;
}

function openEditModal(user: InvitedUserInterface) {
  currentModalUser.value = user;
  showEditModal.value = true;
}

function emitEdit(user: InviteUserInterface, id: number) {
  closeEditModal();
  emit("edit", user, id);
}

function closeEditModal() {
  showEditModal.value = false;
}

function showDetails(userID: number) {
  if (!props.showDetail) {
    return;
  }
  if (!props.user.invitation_sent) {
    toastService.error("Please invite this user to see details", {
      duration: 5000,
      position: "top",
    });
    return;
  }
  router.push(`/participant/${userID}`);
}

function emitDelete(id: number) {
  emit("delete", id);
}

function closeInviteModal() {
  showInviteUserModal.value = false;
}

const formatDate = (dateString: Date | string) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  return date.toLocaleDateString("en-US", options);
};
</script>

<template>
  <div
    class="user-card d-flex border-bottom p-md-3 p-2 align-items-center"
    :class="{
      'cursor-pointer': props.user.invitation_type != Role.Raters,
      'self-rater':
        currentInvitationStore.currentInvitation?.invitation_type ===
          Role.Participants && props.user.rater_type === AllRaterType.Self,
    }"
    @click.stop="showDetails(props.user.id)"
  >
    <ProfileIconComponent :user="props.user" />
    <div class="d-flex flex-column justify-content-center">
      <span class="fw-semibold name text-capitalize"
        >{{ props.user.invited_user.first_name }}
        {{ props.user.invited_user.last_name }}</span
      >
      <span class="email break-word">{{ props.user.invited_user.email }}</span>
      <div
        class="role-wrapper d-flex align-items-center mt-1"
        v-if="props.currentRole === 'participant'"
      >
        <span
          class="invite-tag"
          :class="props.user.invitation_sent ? 'text-secondary' : 'text-danger'"
          >{{ props.user.invitation_sent ? "Invited" : "Not Invited" }}</span
        >
        <span class="pipe">|</span>
        <span class="text-capitalize">{{
          props.user.rater_type
            ? AllRaterTypeNames[props.user.rater_type].display_name
            : ""
        }}</span>
        <span class="pipe"> | </span>
        <span> Created on: {{ formatDate(user.created_at) }} </span>
      </div>
      <div
        class="role-wrapper d-flex align-items-center mt-1"
        v-if="props.currentRole === 'admin' && props.user.invitation_sent"
      >
        <span
          class="invite-tag"
          :class="props.user.invitation_sent ? 'text-secondary' : 'text-danger'"
          >{{ props.user.invitation_sent ? "Invited" : "Not Invited" }}</span
        >
        <span class="pipe">|</span>
        <span> Invited Raters: {{ props.user.item_count }} </span>
        <span class="pipe">|</span>
        <span>Days Left: {{ daysLeft >= 0 ? daysLeft : 0 }}</span>
        <span class="pipe">|</span>
        <span
          >Assessment Completed: {{ props.user?.feedback_completion_count }} /
          {{ props.user.item_count }}</span
        >
        <span class="pipe"> | </span>
        <span> Created on: {{ formatDate(user.created_at) }} </span>
      </div>
      <div
        class="role-wrapper d-flex align-items-center mt-1"
        v-if="props.currentRole === 'admin' && !props.user.invitation_sent"
      >
        <span
          class="invite-tag"
          :class="props.user.invitation_sent ? 'text-secondary' : 'text-danger'"
          >{{ props.user.invitation_sent ? "Invited" : "Not Invited" }}</span
        >
      </div>
      <div
        class="role-wrapper d-flex align-items-center"
        v-if="currentRole === Role.Raters"
      >
        <template v-if="props.user.invitation_sent">
          <span
            v-if="props.user.meta.feedback_done"
            class="text-success fw-semibold"
            >Assessment Submitted Successfully</span
          >
          <span class="text-danger fw-semibold" v-else
            >Assessment Submission Pending</span
          >
        </template>
        <span class="text-warning fw-semibold" v-else>Rater Not Invited</span>
        <span class="pipe"> | </span>
        <span> Created on: {{ formatDate(user.created_at) }} </span>
      </div>
    </div>
    <div
      v-if="rendericon"
      class="d-flex align-items-center ms-auto action-items"
    >
      <button
        title="Send Invitation Email"
        class="btn border-0 p-2"
        @click.stop="showInviteUserModal = true"
      >
        <i class="bi bi-envelope"></i>
      </button>
      <button
        v-if="!user.invitation_sent"
        title="Edit User"
        class="btn border-0 p-2"
        @click.stop="openEditModal(props.user)"
      >
        <i class="bi bi-pencil-square"></i>
      </button>
      <button
        v-if="!user.invitation_sent"
        title="Delete Invitation"
        class="btn border-0 p-2"
        @click.stop="openDeleteModal(props.user.id)"
      >
        <i class="bi bi-trash3"></i>
      </button>
      <button v-if="showDetail" title="View More" class="btn border-0 p-2">
        <i class="bi bi-arrow-up-right-square"></i>
      </button>
    </div>
  </div>
  <DeleteUserModal
    v-if="showDeleteModal"
    :invitation="props.user"
    @close="showDeleteModal = false"
    @delete="emitDelete"
  />

  <EditUserModal
    v-if="showEditModal"
    :user="currentModalUser"
    @close="closeEditModal"
    @edit="emitEdit"
  />
  <UserInvitationModal
    v-if="showInviteUserModal"
    :invitation="props.user"
    @invite="emitInvite"
    @close="closeInviteModal"
  />
</template>

<style lang="scss" scoped>
@import "../../theme/variables";

.user-card {
  transition: all ease 300ms;
  gap: 12px;
  order: 2;
}

.self-rater {
  order: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.email {
  font-size: 12px;
  color: #6e6e6e;
}

.invite-tag {
  font-weight: 500;
  font-size: 10px;
}

.role-wrapper {
  gap: 10px;
  span {
    font-size: 12px;
  }
}

.action-items {
  gap: 10px;

  .btn {
    font-size: 18px;

    transition: ease 300ms;
  }
}

@media (max-width: 575px) {
  .role-wrapper {
    flex-direction: column;
    align-items: start !important;
    gap: 0;
  }
  .pipe {
    display: none;
  }
  .action-items {
    justify-content: center;
    flex-direction: column;
    gap: 8;
    .btn {
      font-size: 16px;
      padding: 2px !important;
    }
  }
}
</style>
