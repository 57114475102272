import { InvitedUserInterface } from "../interface/UserInterface";

export function getDaysLeft(invitation: InvitedUserInterface): number {
  const now = new Date();
  const completionDate = new Date(invitation!.meta.feedback_completion_date);

  const timeDiff = completionDate.getTime() - now.getTime();

  return Math.floor(timeDiff / (1000 * 60 * 60 * 24));
}
