<script lang="ts" setup>
import { onBeforeMount, ref } from "vue";
import {
  Role,
  InvitedUserInterface,
  InviteUserInterface,
  UserInterface,
  RaterTypeNames,
  // DurationDays,
  Roles,
} from "../../../interface/UserInterface";
import { appContainer } from "../../../container";
import { UserService } from "../../../services/userService";
import { debounce } from "../../../utils/debounce";
import { ErrorResponse } from "../../../interface/ErrorInterface";
import { AxiosError } from "axios";
import { LoadingService } from "../../../services/loadingService";
import { ToastService } from "../../../services/toastService";
import ValidationErrorComponent from "../../../components/ValidationErrorComponent.vue";
import { CompanyService } from "../../../services/manageCompanyService";
import { ActiveRolesStore } from "../../../stores/ActiveRolesStore";
import { useUserStore } from "../../../stores/UserStore";

const emit = defineEmits(["close", "add"]);
const props = defineProps<{
  companyId?: number;
  forAdmin?: boolean;
}>();

const userType = Role;
const userService = appContainer.resolve(UserService);
const companyService = appContainer.resolve(CompanyService);
const activeRolesStore = ActiveRolesStore();
const userStore = useUserStore();

const loadingService = appContainer.resolve(LoadingService);
const toastService = appContainer.resolve(ToastService);

const userData = ref<InviteUserInterface>({
  first_name: "",
  last_name: "",
  email: "",
  invited_for: null,
  invitation_type: "",
  rater_type: null,
  duration_days: null,
  admin_id: null,
  allowed_participants: null,
});

const invitationFor = ref<InvitedUserInterface | null>(null);
const raterTypeValue = ref("");
// const durationDays = DurationDays;

const allAdmins = ref<InvitedUserInterface[]>([]);
const allParticipants = ref<InvitedUserInterface[]>([]);

onBeforeMount(async () => {
  userStore.getUser();
  if (props.forAdmin) {
    userData.value.invitation_type = Role.Admin;
  } else {
    await getAllAdmins();
    await getAllParticipant();
  }
});

async function getAllAdmins() {
  const isAll = true;
  return userService
    .getInvitedUser({
      invitationType: Role.Admin,
      all: isAll,
      ...(props.companyId
        ? {
            companyId: props.companyId,
          }
        : {}),
      year: new Date().getFullYear(),
    })
    .then((result) => {
      allAdmins.value = result.items.filter(
        (item) => item.invitation_sent === true,
      );
    });
}

async function setAdminId() {
  if (userData.value.invitation_type === Role.Raters) {
    userData.value.admin_id = invitationFor.value!.invited_for_user!.id;
  }
}

async function getAllParticipant() {
  const isAll = true;
  return userService
    .getInvitedUser({
      invitationType: Role.Participants,
      all: isAll,
      ...(props.companyId
        ? {
            companyId: props.companyId,
          }
        : {}),
      year: new Date().getFullYear(),
    })
    .then((result) => {
      allParticipants.value = result.items.filter(
        (item) => item.invitation_sent === true,
      );
    });
}

async function emitAdd() {
  // Validate form fields
  if (!validateForm()) {
    return;
  }

  if (invitationFor.value != null) {
    userData.value.invited_for = invitationFor.value.invited_user!.id;
  }
  if (raterTypeValue.value != "") {
    userData.value.rater_type = raterTypeValue.value;
  }
  if (userData.value.duration_days === 0) {
    userData.value.duration_days = null;
  }

  if (userData.value.invitation_type === Role.Admin) {
    if (activeRolesStore.activeRoles.includes("super_admin")) {
      userData.value.superadmin_id = userStore.user?.id;
    } else if (activeRolesStore.activeRoles.includes(Role.Admin)) {
      await getCurrentAdmin().then((result) => {
        userData.value.superadmin_id = (
          result as unknown as InvitedUserInterface
        ).superadmin_id;
      });
    }
  }

  const loader = await loadingService.show();
  if (props.companyId) {
    companyService
      .inviteEmployee(userData.value, props.companyId)
      .then(() => {
        toastService.success("User Added Successfully");
        emit("add", userData.value.invitation_type);
      })
        .catch((err) => {
        const error = err as AxiosError;
        const errorResponse = error.response?.data as ErrorResponse;
        validationErrors.value = errorResponse;
      })
      .finally(() => loader.hide());
  } else {
    userService
      .addUser(userData.value)
      .then(() => {
        toastService.success("User Added Successfully");
        emit("add");
      })
      .catch((err) => {
        const error = err as AxiosError;
        const errorResponse = error.response?.data as ErrorResponse;
        validationErrors.value = errorResponse;
      })
      .finally(() => loader.hide());
  }
}

async function getCurrentAdmin() {
  const loader = await loadingService.show();
  return await userService
    .getInvitedUser({
      invited_user: userStore.user?.id,
      invitationType: Role.Admin,
      invitation_sent: true,
      allUsers: true,
      companyId: props.companyId,
    })
    .then((result) => result.items[0])
    .finally(() => loader.hide());
}

const existingUsers = ref<UserInterface[]>([]);

function updateInputFields() {
  disabled.value = false;
}

let previousController: AbortController | undefined;

const fetchEmails = debounce(function fetchEmails() {
  previousController?.abort();
  previousController = undefined;
  if (userData.value.email.length === 0) {
    existingUsers.value = [];
    updateInputFields();
    return;
  }

  if (userData.value.email.length >= 3) {
    const pageConfig = { page: 1, offset: 8 };
    previousController = new AbortController();
    userService
      .getUserWithRole(
        "",
        true,
        pageConfig,
        userData.value.email,
        previousController.signal,
      )
      .then((result) => {
        existingUsers.value = result.items;
        showSuggestion.value = true;
        if (existingUsers.value) {
          updateInputFields();
        }
      });
    if (existingUsers.value) {
      updateInputFields();
    }
  }
}, 500);

const showSuggestion = ref(false);
const disabled = ref(false);
const validationErrors = ref<ErrorResponse>();

// Validation states
const formErrors = ref({
  email: "",
  first_name: "",
  invitation_type: "",
  invited_for: "",
  rater_type: "",
  durationDays: "",
});

function validateForm() {
  let isValid = true;
  formErrors.value = {
    email: "",
    first_name: "",
    invitation_type: "",
    invited_for: "",
    rater_type: "",
    durationDays: "",
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const nameRegex = /^[A-Za-z]+$/;

  if (!userData.value.email) {
    formErrors.value.email = "Email is required.";
    isValid = false;
  } else if (!emailRegex.test(userData.value.email)) {
    formErrors.value.email = "Email is not valid.";
    isValid = false;
  }

  if (!userData.value.first_name) {
    formErrors.value.first_name = "First name is required.";
    isValid = false;
  } else if (!nameRegex.test(userData.value.first_name)) {
    formErrors.value.first_name = "First name can only contain letters.";
    isValid = false;
  }

  if (!userData.value.invitation_type) {
    formErrors.value.invitation_type = "Please select a user type.";
    isValid = false;
  }

  const durationDays = Number(userData.value.duration_days);

  if (userData.value.invitation_type === Role.Participants) {
    switch (true) {
      case !durationDays:
        formErrors.value.durationDays =
          "Please select the assessment duration.";
        isValid = false;
        break;
      case durationDays < 1:
        formErrors.value.durationDays =
          "Assessment duration needs to be greater than 0";
        isValid = false;
        break;
      case durationDays > 30:
        formErrors.value.durationDays =
          "Assessment duration needs to be less than 30";
        isValid = false;
        break;
    }
  }

  if (
    userData.value.invitation_type === Role.Participants &&
    !invitationFor.value
  ) {
    formErrors.value.invited_for = "Please select an admin.";
    isValid = false;
  }

  if (userData.value.invitation_type === Role.Raters && !invitationFor.value) {
    formErrors.value.invited_for = "Please select a participant.";
    isValid = false;
  }

  if (userData.value.invitation_type === Role.Raters && !raterTypeValue.value) {
    formErrors.value.rater_type = "Please select a rater type.";
    isValid = false;
  }

  return isValid;
}

function setUserInfo(user: UserInterface) {
  userData.value.email = user.email;
  userData.value.first_name = user.first_name;
  userData.value.last_name = user.last_name;
  showSuggestion.value = false;
  disabled.value = true;
}

function closeDropdown() {
  setTimeout(() => {
    if (userData.value.email.length != 0) {
      const user = existingUsers.value.find(
        (user) => user.email == userData.value.email,
      );
      if (user) {
        setUserInfo(user as UserInterface);
      } else {
        updateInputFields();
        showSuggestion.value = false;
      }
    }
  }, 200);
}
</script>

<template>
  <div class="modal-backdrop">
    <div class="modal fade show" id="add-modal">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <p class="modal-title fw-bold">
              Add {{ props.companyId ? "Person" : "User" }}
            </p>
            <button
              type="button"
              class="btn-close"
              @click="emit('close')"
            ></button>
          </div>
          <ValidationErrorComponent
            v-if="validationErrors"
            :validations="validationErrors"
          ></ValidationErrorComponent>
          <form @submit.prevent="emitAdd" class="w-100 overflow-y-auto">
            <div class="modal-body">
              <div class="mb-3">
                <label class="form-label fw-bold">Email</label>
                <div class="position-relative">
                  <input
                    type="email"
                    maxlength="50"
                    placeholder="Email"
                    class="form-control email"
                    v-model="userData.email"
                    @input="fetchEmails"
                    @blur="closeDropdown"
                    data-cy=""
                  />
                  <div v-if="formErrors.email" class="text-danger mt-2 error">
                    {{ formErrors.email }}
                  </div>
                  <!-- <ul
                    class="position-absolute email-list list-unstyled"
                    v-show="showSuggestion"
                  >
                    <li
                      v-for="user in existingUsers"
                      :key="user.id"
                      :value="user.email"
                      @click="setUserInfo(user)"
                    >
                      {{ user.email }}
                    </li>
                  </ul> -->
                </div>
              </div>
              <div class="mb-3">
                <label class="form-label fw-bold">First Name</label>
                <input
                  type="text"
                  placeholder="First Name"
                  class="form-control"
                  maxlength="14"
                  v-model="userData.first_name"
                  :disabled="disabled"
                  data-cy=""
                />
                <div
                  v-if="formErrors.first_name"
                  class="text-danger mt-2 error"
                >
                  {{ formErrors.first_name }}
                </div>
              </div>
              <div class="mb-3">
                <label class="form-label fw-bold">Last Name</label>
                <input
                  type="text"
                  placeholder="Last Name"
                  class="form-control"
                  v-model.trim="userData.last_name"
                  maxlength="14"
                  :disabled="disabled"
                  data-cy=""
                />
              </div>
              <div class="mb-3" v-if="!props.forAdmin">
                <label class="form-label fw-bold">User Type</label>
                <select
                  class="form-select"
                  v-model="userData.invitation_type"
                  :class="{ 'is-invalid': formErrors.invitation_type }"
                >
                  <option value="">Please Select</option>
                  <template v-for="role in userType" :key="role">
                    <option
                      :value="role"
                      :disabled="
                        !userStore.user?.certification &&
                        role === Role.Admin &&
                        !activeRolesStore.activeRoles.includes(Roles.SuperAdmin)
                      "
                      data-cy=""
                    >
                      {{ role }}
                    </option>
                  </template>
                </select>
                <div
                  v-if="formErrors.invitation_type"
                  class="text-danger mt-2 error"
                >
                  {{ formErrors.invitation_type }}
                </div>
              </div>
              <!-- <template v-if="userData.invitation_type === Role.Admin">
                <div class="mb-3">
                  <label class="form-label fw-bold">Allowed Participant</label>
                  <input
                    type="number"
                    placeholder="Allowed Participant"
                    class="form-control"
                    v-model="userData.allowed_participants"
                    data-cy=""
                    min="1"
                    max="50"
                  />
                </div>
              </template> -->
              <template v-if="userData.invitation_type === Role.Participants">
                <div class="mb-3">
                  <label class="form-label fw-bold">Select Admin</label>
                  <select
                    class="form-select"
                    v-model="invitationFor"
                    :class="{ 'is-invalid': formErrors.invited_for }"
                    data-cy=""
                  >
                    <option :value="null">Please Select Admin</option>
                    <option
                      v-for="admin in allAdmins"
                      :key="admin.invited_user.id"
                      :value="admin"
                    >
                      {{ admin.invited_user.first_name }}
                      {{ admin.invited_user.last_name }}
                    </option>
                  </select>
                  <div
                    v-if="formErrors.invited_for"
                    class="text-danger mt-2 error"
                  >
                    {{ formErrors.invited_for }}
                  </div>
                </div>
                <div class="mb-3">
                  <label class="form-label fw-bold">Assessment Duration</label>
                  <input
                    type="number"
                    placeholder="Please Select Assessment Duration"
                    class="form-control"
                    data-cy=""
                    v-model="userData.duration_days"
                    min="1"
                    max="30"
                  />
                  <!-- <select class="form-select" v-model="userData.duration_days">
                    <option value="">Please Select Assessment Duration</option>
                    <option
                      v-for="(duration, index) in durationDays"
                      :key="index"
                      :value="duration"
                    >
                      {{ duration }}
                    </option>
                  </select> -->
                  <div
                    v-if="formErrors.durationDays"
                    class="text-danger mt-2 error"
                  >
                    {{ formErrors.durationDays }}
                  </div>
                </div>
              </template>
              <template v-if="userData.invitation_type === Role.Raters">
                <div class="mb-3">
                  <label class="form-label fw-bold">Select Participant</label>
                  <select
                    class="form-select"
                    v-model="invitationFor"
                    :class="{ 'is-invalid': formErrors.invited_for }"
                    data-cy=""
                    @change="setAdminId"
                  >
                    <option :value="null">Please Select Participant</option>
                    <option
                      v-for="participant in allParticipants"
                      :key="participant.invited_user.id"
                      :value="participant"
                    >
                      {{ participant.invited_user.first_name }}
                      {{ participant.invited_user.last_name }} (Admin:
                      {{ participant.invited_for_user?.first_name }}
                      {{ participant.invited_for_user?.last_name }})
                    </option>
                  </select>
                  <div
                    v-if="formErrors.invited_for"
                    class="text-danger mt-2 error"
                  >
                    {{ formErrors.invited_for }}
                  </div>
                </div>
                <div class="mb-3">
                  <label class="form-label fw-bold">Rater Type</label>
                  <select
                    class="form-select"
                    v-model="raterTypeValue"
                    :class="{ 'is-invalid': formErrors.rater_type }"
                    data-cy=""
                  >
                    <option value="">Please Select</option>
                    <template v-for="rater in RaterTypeNames" :key="rater">
                      <option :value="rater.value">
                        {{ rater.display_name }}
                      </option>
                    </template>
                  </select>
                  <div
                    v-if="formErrors.rater_type"
                    class="text-danger mt-2 error"
                  >
                    {{ formErrors.rater_type }}
                  </div>
                </div>
              </template>
            </div>
            <div class="modal-footer">
              <button
                type="submit"
                class="btn btn-primary btn-sm mx-auto text-white"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
select {
  text-transform: capitalize;
}

ul {
  max-height: 280px;
  width: 100%;
  overflow-y: auto;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  li {
    padding: 4px 6px;
    cursor: pointer;
    &:hover {
      background-color: #00000050;
    }
  }
}

.is-invalid {
  border-color: red;
}
.text-danger {
  font-size: 0.875em;
}
</style>
