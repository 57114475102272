<script lang="ts" setup>
import { onBeforeMount, ref } from "vue";
import { appContainer } from "../../../container";
import { AuthService } from "../../../services/authService";
import {
  AllRaterType,
  InvitedUserInterface,
  Role,
} from "../../../interface/UserInterface";
import { UserService } from "../../../services/userService";
import { ToastService } from "../../../services/toastService";
import { LoadingService } from "../../../services/loadingService";
import { PagingInterface } from "../../../interface/PaginationInterface";
import AddRaterModal from "../../admin/modal/AddUserModal.vue";
import UserListingCard from "../../admin/UserCardListing.vue";
import router from "../../../routes";
import { CompanyService } from "../../../services/manageCompanyService";
import { useUserStore } from "../../../stores/UserStore";
import { ActiveRolesStore } from "../../../stores/ActiveRolesStore";
import { AxiosError } from "axios";
import { ErrorResponse } from "../../../interface/ErrorInterface";
import { getDaysLeft } from "../../../utils/getDaysleft";

const authService = appContainer.resolve(AuthService);
const userService = appContainer.resolve(UserService);
const toastService = appContainer.resolve(ToastService);
const loadingService = appContainer.resolve(LoadingService);
const companyService = appContainer.resolve(CompanyService);

const showAddModal = ref(false);
const paginatedData = ref<PagingInterface<InvitedUserInterface>>();
const invitedUsers = ref<InvitedUserInterface[]>([]);
const isSelfRater = ref(false);
const isLoading = ref(true);
const selfRater = ref<InvitedUserInterface>();
const userStore = useUserStore();
const participant = ref<InvitedUserInterface | null>(null);
const activeRoleStore = ActiveRolesStore();
const daysLeft = ref(0);
onBeforeMount(async () => {
  await userStore.getInvitation(
    {
      invited_user: userStore.user?.id,
      invitationType: Role.Participants,
      all: true,
      allUsers: true,
    },
    "participant",
  );
  participant.value = userStore.invitation;

  if (participant.value) {
    daysLeft.value = getDaysLeft(participant.value);
  }
  await getRaters();
  isLoading.value = false;
});

async function getRaters(
  pageConfig: { page?: number; offset?: number } = {
    page: 1,
    offset: 8,
  },
) {
  const loader = await loadingService.show();
  isLoading.value = true;
  return userService
    .getInvitedUser(
      {
        invitationType: Role.Raters,
        invited_for: userStore.user?.id,
        allUsers: true,
        all: true,
        adminId: participant.value?.invited_for_user?.id,
      },
      pageConfig,
    )
    .then((result) => {
      paginatedData.value = result;
      invitedUsers.value = result.items;
      selfRater.value = invitedUsers.value.find(
        (item) =>
          item.rater_type === AllRaterType.Self &&
          participant.value?.invited_for_user?.id === item.meta.admin_id,
      );
      isSelfRater.value = !!selfRater.value;
    })
    .finally(() => {
      loader.hide();
      isLoading.value = false;
    });
}

async function addUser() {
  showAddModal.value = false;
  getRaters();
}

async function setSelfRater() {
  isLoading.value = true;
  const userData = {
    first_name: userStore.user!.first_name,
    last_name: userStore.user!.last_name,
    email: userStore.user!.email,
    invited_for: userStore.user!.id,
    invitation_type: Role.Raters,
    rater_type: AllRaterType.Self,
    admin_id: participant.value?.invited_for_user?.id,
    duration_days: null,
  };

  await companyService
    .inviteEmployee(userData, participant.value!.company_id as number)
    .then(() => {
      toastService.success("You have been added as a Self Rater");

      isSelfRater.value = true;
      getRaters();
    })
    .catch((err) => {
      const error = err as AxiosError;
      const errorResponse = error.response?.data as ErrorResponse;

      if (errorResponse?.errors) {
        const flattenedErrors = Object.values(errorResponse.errors).flat();
        toastService.error(flattenedErrors.join("<br>"));
      }
    })
    .finally(() => (isLoading.value = false));

  await authService.fetchUser();
  await activeRoleStore.getActiveRole();
}

async function handleRateYourSelf() {
  if (
    participant.value?.meta.feedback_done ||
    (participant.value?.days_left as number) < 0
  ) {
    toastService.error(
      "Feedback is already completed or the deadline has passed.",
    );
    return;
  }
  const selfRater = await userService
    .getInvitedUser({
      invitationType: Role.Raters,
      invited_for: userStore.user?.id,
      all: true,
      companyId: userStore.invitation?.company_id,
    })
    .then((result) => {
      return result.items.find((item) => item.rater_type === AllRaterType.Self);
    });

  userStore.setRater(selfRater as InvitedUserInterface);
  // const activeRole = authService
  //   .user()
  //   ?.roles.filter((role) => role.role_name === Role.Raters)[0];
  // await storageService.set(StorageKeys.Current_Invitation, selfRater.value);

  // await storageService.set(StorageKeys.Active_Roles, activeRole);
  // await invitationStore.getCurrentInvitation();
  // await activeRoleStore.getActiveRole();
  router.replace("/assessment/instruction");
}
</script>
<template>
  <template v-if="!isLoading">
    <div class="d-flex w-100 justify-content-between flex-wrap mb-4 gap-10">
      <h2 class="fw-medium fs-4 m-0">
        Manage Raters
        <span
          class="text-danger fs-6"
          v-if="participant?.meta.feedback_done || daysLeft < 0"
          >(Assessment Over)</span
        >
      </h2>
      <div
        v-if="!participant?.meta.feedback_done && daysLeft >= 0"
        class="btn-wrapper d-flex align-items-center flex-wrap gap-4"
      >
        <button
          class="btn btn-secondary btn-sm text-white"
          v-if="isSelfRater && !selfRater?.meta.feedback_done"
          @click="handleRateYourSelf"
        >
          Rate Yourself
        </button>
        <button
          class="btn btn-primary btn-sm text-white"
          @click="showAddModal = true"
          v-if="isSelfRater"
        >
          Add Rater
        </button>
        <button
          class="btn btn-secondary btn-sm text-white"
          @click="setSelfRater"
          v-else
        >
          Add Yourself as Rater
        </button>
      </div>
    </div>
    <template
      v-if="!isSelfRater && !participant?.meta.feedback_done && daysLeft >= 0"
    >
      <p class="fw-bold">Let's get started!</p>
      <ol>
        <li class="mb-3">
          You will first need to set yourself up as the
          <strong>Self Rater</strong>. This will then allow you to proceed with
          entering your TL360° Raters. Click on the 'Add Yourself as Rater'
          button above.
        </li>
        <li class="mb-3">
          Once you have added yourself as a Rater, you will be able to add
          Raters for other categories, such as Supervisor, Peer, Direct Report,
          and Other. You will need to provide the following details to add them:

          <ol class="child">
            <li>Names (First and Last)</li>
            <li>Email address (please ensure accuracy)</li>
            <li>
              Rater designation: Supervisor, Peer, Direct Report, Other
              <i
                >(The 'Other' category is flexible and can include Friends,
                Family, Customers, Board Members, etc.)
              </i>
            </li>
          </ol>
        </li>
        <li class="mb-3">
          You are required to add at least 15-20 Raters (if possible).
        </li>
        <!-- <li>
        Send the Trusted Leader 360° invitation to your raters. Click "Send
        All".
      </li> -->
      </ol>
    </template>
    <template v-else>
      <div class="all-raters" v-if="invitedUsers.length">
        <UserListingCard
          v-for="user in invitedUsers"
          :key="user.id"
          :user="user"
          :showDetail="false"
          :currentRole="Role.Participants"
          @invite="getRaters"
          @delete="getRaters"
          @edit="getRaters"
        />
      </div>
      <div
        class="no-result d-flex align-items-center justify-content-center text-opacity-50 text-danger"
        v-else-if="isLoading"
      >
        No Records Found
      </div>
    </template>

    <AddRaterModal
      v-if="showAddModal"
      @close="showAddModal = false"
      @add="addUser"
      :type="Role.Raters"
      :for="userStore.user?.id"
    />
  </template>
</template>

<style scoped lang="scss">
.no-result {
  height: 50vh;
}
ol {
  max-width: 900px;
}
.child {
  list-style: lower-alpha;
}
.gap-10 {
  gap: 10px;
}
</style>
